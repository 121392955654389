.body {
  overflow: scroll;
  font-family: Circular, sans-serif;
}

.app {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.interface {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.render {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #c2c2c2;
}

.slide {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide.amenity-slide {
  padding-bottom: 80px;
}

.nav {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 20px;
}

.nav-content {
  position: relative;
  z-index: 2000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #05163d;
  line-height: 20px;
}

.nav-section-title {
  min-width: 200px;
  padding-left: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  font-size: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.instructions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  margin-left: -100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.instruction {
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
}

.instruction-divider {
  margin-right: 10px;
  margin-left: 10px;
  color: #ff521b;
}

.instruction-reset {
  padding-top: 3px;
  padding-bottom: 3px;
  color: #ff521b;
  font-weight: 500;
  cursor: pointer;
}

.instruction-reset:hover {
  color: #fff;
}

.nav-burger {
  width: 80px;
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #ff521b;
  background-image: url("../images/burger-icon.svg");
  background-position: 50% 50%;
  background-size: 22px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.nav-burger:hover {
  background-color: #fff;
  background-image: url("../images/burger-icon-hover.svg");
}

.nav-burger.nav-burger-close {
  background-color: #fff;
  background-image: url("../images/burger-close-hover.svg");
}

.nav-burger.nav-burger-close:hover {
  background-color: #ff521b;
  background-image: url("../images/burger-close.svg");
}

.slide-content {
  position: absolute;
  left: 0%;
  top: 0%;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: 80px 40px 50px;
  color: #fff;
}

.slide-panel {
  width: 380px;
  height: auto;
  border-radius: 6px;
  background-color: #fff;
  background-image: url("../images/fold-slide-logo.svg");
  background-position: 351px 16px;
  background-size: auto;
  background-repeat: no-repeat;
}

.slide-panel.floors-panel {
  width: 230px;
  padding: 15px 20px;
  background-color: #05163d;
  background-position: 197px 16px;
}

.slide-panel.amenity-panel {
  width: 100%;
  margin-bottom: 80px;
  padding-bottom: 80px;
  background-position: 97% 16px;
}

.slide-panel.contact-panel {
  width: 100%;
  height: 85vh;
  background-color: #fff;
  background-position: 98% 16px;
}

.slide-panel.amenity-info-panel {
  width: 50%;
  background-color: #05163d;
  background-image: none;
}

.overview-slide-top {
  position: relative;
  height: 300px;
  margin-bottom: 0px;
  padding-right: 40px;
}

.overview-slide-top.full-width {
  height: 320px;
  padding-right: 0px;
}

.overview-img-container {
  position: relative;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 60px;
  background-image: url("../images/overview-hero-placeholder.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.slide-header {
  margin-top: 0px;
  margin-bottom: 25px;
  color: #05163d;
  line-height: 39px;
  font-weight: 500;
  text-align: center;
}

.overview-slide-content {
  position: relative;
  padding: 30px 40px 40px;
}

.overview-slide-content.connections-content {
  color: #05163d;
}

.fold-outline {
  color: #05163d;
}

.slide-copy {
  color: #05163d;
  font-size: 16px;
  text-align: center;
}

.img-expand-button {
  position: absolute;
  z-index: 100;
  width: 40px;
  height: 40px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 16px;
  background-color: #ff521b;
  background-image: url("../images/expand-icon.svg");
  background-position: 45% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #ff521b;
  cursor: pointer;
}

.img-expand-button:hover {
  background-color: #fff;
  background-image: url("../images/expand-icon-hover.svg");
}

.slide-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide-nav.slide-nav-condensed {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slide-nav-item {
  padding: 5px;
  color: #ff521b;
  font-size: 16px;
  cursor: pointer;
}

.slide-nav-item:hover {
  color: #05163d;
}

.slide-div-item {
  width: 1px;
  height: 23px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: #e6e6e6;
}

.slide-sub-panel {
  width: 380px;
  margin-top: 16px;
}

.slide-sub-panel.floor-sub {
  width: 230px;
  border-radius: 3px;
  background-color: #fff;
}

.slide-sub-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.slide-sub-button {
  position: relative;
  width: 49.5%;
  padding-left: 35px;
  border-radius: 5px;
  background-color: #048837;
  background-image: url("../images/button-arrow.svg");
  background-position: 93% 50%;
  background-size: auto 20px;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer;
}

.slide-sub-button:hover {
  background-color: #fff;
  color: #05163d;
}

.slide-sub-button.wellness-button {
  background-color: #05163d;
}

.slide-sub-button-label {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  font-size: 18px;
}

.sustainability-tree {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  z-index: 0;
  width: 61px;
  height: 75px;
  background-image: url("../images/sustainability-tree.png");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.wellness-flower {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 80px;
  height: 65px;
  background-image: url("../images/wellness-flower.png");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.overview-bee {
  position: absolute;
  left: -6%;
  top: 5%;
  right: auto;
  bottom: auto;
  width: 60px;
  height: 50px;
  background-image: url("../images/overview-bee.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.overview-butterfly {
  position: absolute;
  left: auto;
  right: -10%;
  bottom: -8%;
  z-index: 100;
  width: 83px;
  height: 61px;
  background-image: url("../images/overview-butterfly.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.amenities-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  padding: 60px 40px;
}

.amenities-gfk1 {
  position: absolute;
  left: 22px;
  top: 9%;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 36px;
  height: 39px;
  background-image: url("../images/amenity-gfk1.svg");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.amenities-noodles {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: -11%;
  width: 85px;
  height: 109px;
  background-image: url("../images/amenity-noodles.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.amenities-chair {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 100px;
  height: 120px;
  background-image: url("../images/amenity-chair.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.amenities-gfk2 {
  position: absolute;
  left: auto;
  top: auto;
  right: 4%;
  bottom: 11%;
  width: 28px;
  height: 25px;
  background-image: url("../images/amenity-gfk2.svg");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.connections-hero {
  width: 100%;
  height: 100%;
  background-image: url("../images/connections-hero.jpg");
  background-position: 25% 70%;
  background-size: 300px;
  background-repeat: no-repeat;
}

.connections-gfk1 {
  position: absolute;
  left: 6%;
  top: 8%;
  right: auto;
  bottom: auto;
  width: 25px;
  height: 45px;
  background-image: url("../images/connections-gfk1.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.connections-gfk2 {
  position: absolute;
  left: auto;
  top: auto;
  right: 5%;
  bottom: 4%;
  width: 20px;
  height: 20px;
  background-image: url("../images/connections-gfk2.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.connections-lines {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: -3%;
  width: 112px;
  height: 134px;
  background-image: url("../images/connections-line.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.connections-tube {
  position: absolute;
  left: -7%;
  top: auto;
  right: auto;
  bottom: -7%;
  width: 111px;
  height: 119px;
  background-image: url("../images/connections-tube.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.return-button {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  background-image: url("../images/button-arrow-return.svg");
  background-position: 0% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #ff521b;
  cursor: pointer;
}

.return-button:hover {
  color: #fff;
}

.return-button.return-button-white {
  position: absolute;
  left: 10px;
  top: 10px;
  right: auto;
  bottom: auto;
  padding: 6px 12px 7px 28px;
  border-radius: 3px;
  background-color: #fff;
  background-position: 9px 49%;
}

.return-button.return-button-white:hover {
  color: #05163d;
}

.floor-title {
  margin-top: 40px;
  margin-bottom: 0px;
  color: #fff;
  font-weight: 500;
}

.floor-selector-list {
  padding-top: 5px;
  padding-bottom: 5px;
}

.floor-selector-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 18px 8px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  color: #ff521b;
  cursor: pointer;
}

.floor-selector-item:hover {
  color: #05163d;
}

.floor-selector-item.floor-selector-item-active {
  background-color: #ff521b;
  color: #fff;
}

.floor-selector-label {
  min-width: 100px;
}

.floor-selector-value {
  min-width: 70px;
  opacity: 0.4;
  text-align: right;
}

.intro-slide {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e6e6e6;
}

.intro-portal {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 50%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 1000px;
  border-top-right-radius: 1000px;
  background-color: #ddd;
}

.intro-loader-container {
  position: absolute;
  top: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.loader-logo {
  width: 142px;
  height: 53px;
  margin-bottom: 0px;
  background-image: url("../images/loader-fold-logo.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.loading-text {
  margin-top: 0px;
  color: #bbb;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 3px;
}

.loading-text.green-text {
  color: #048837;
}

.intro-hero-container {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.intro-hero-img {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../images/overview-1-hero.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.intro-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 99;
}

.intro-fold-logo {
  position: relative;
  width: 436px;
  height: 162px;
  background-image: url("../images/intro-fold-logo.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-start-button {
  position: relative;
  margin-top: 90px;
  padding: 8px 40px 8px 15px;
  border-radius: 20px;
  background-color: #fff;
  background-image: url("../images/button-arrow.svg");
  background-position: 84% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #ff521b;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
}

.intro-start-button:hover {
  background-color: #05163d;
  color: #ff521b;
}

.intro-start-button.completion {
  margin-top: 30px;
  padding: 8px 40px 8px 15px;
  background-position: 90% 50%;
}

.intro-sub-container {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.intro-sub-content {
  width: auto;
  padding: 15px 45px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #05163d;
  color: #ff521b;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

.overlays {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 3000;
}

.overlay {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 3000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  padding: 50px 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.overlay-bg {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(5, 22, 61, 0.37);
}

.overlay-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 85vh;
  border-radius: 4px;
  background-color: #e6e6e6;
}

.overlay-content.wellness-content {
  background-color: #048837;
}

.overlay-content.planview-content {
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #d9e4d2;
}

.overlay-col-left {
  width: 390px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 45px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.overlay-sub-title {
  margin-top: 40px;
  margin-bottom: 40px;
}

.overlay-copy {
  font-size: 20px;
  line-height: 26px;
}

.overlay-col-right {
  width: 100%;
  padding: 40px 45px;
}

.overlay-featurespec-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 135px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.overlay-featurespec-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  margin-bottom: 40px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.featurespec-label {
  color: #048837;
  font-size: 18px;
}

.featurespec-icon {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
}

.featurespec-icon.carbon-spec {
  height: 45px;
  background-image: url("../images/spec-carbon.svg");
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.featurespec-icon.breeam-spec {
  height: 45px;
  background-image: url("../images/spec-breeam.svg");
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.featurespec-icon.solar-spec {
  height: 45px;
  background-image: url("../images/spec-solar.svg");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.featurespec-icon.spec-electric {
  height: 45px;
  background-image: url("../images/spec-electric.svg");
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-list {
  width: 50%;
  padding-right: 40px;
}

.spec-item {
  max-width: 500px;
  margin-bottom: 15px;
  padding-left: 19px;
  background-image: url("../images/spec-icon.svg");
  background-position: 0% 7px;
  background-size: auto;
  background-repeat: no-repeat;
  color: #05163d;
  font-size: 14px;
  line-height: 20px;
}

.wellness-gallery {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.overlay-close {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 60px;
  height: 50px;
  border-bottom-left-radius: 6px;
  background-color: #ff521b;
  background-image: url("../images/overlay-close-icon.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  cursor: pointer;
}

.overlay-close:hover {
  background-color: #05163d;
}

.wellness-gallery-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  height: 470px;
  margin-bottom: -25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-image: url("../images/wellness-placeholder.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.wellness-gallery-text {
  margin-bottom: 0px;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
}

.wellness-thumb-container {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.thumb {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  margin-left: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 20px;
}

.thumb.thumb-active {
  border-color: #ff521b;
  background-color: #ff521b;
}

.thumb.dark-thumb {
  border-color: #e6e6e6;
}

.thumb.dark-thumb.thumb-active {
  border-color: #ff521b;
}

.wellness-gallery-controls {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wellness-gallery-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.wellness-gallery-button:hover {
  border-radius: 4px;
  background-color: #ff521b;
}

.wellness-gallery-button.next-button {
  width: 40px;
  height: 40px;
  background-image: url("../images/gallery-next.svg");
  background-position: 50% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
}

.wellness-gallery-button.prev-button {
  width: 40px;
  height: 40px;
  background-image: url("../images/gallery-prev.svg");
  background-position: 50% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
}

.gallery {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.gallery-image {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.gallery-controls {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 17px;
  background-color: #fff;
}

.gallery-label {
  width: 100%;
  padding-top: 10px;
  color: #05163d;
}

.gallery-controls-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.gallery-button {
  width: 45px;
  height: 45px;
  background-color: #ff521b;
  cursor: pointer;
}

.gallery-button:hover {
  background-color: #05163d;
}

.gallery-button.next-button {
  background-image: url("../images/gallery-next.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.gallery-button.prev-button {
  background-image: url("../images/gallery-prev.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.gallery-thumb-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.plan-image {
  width: 90%;
  height: 60vh;
  background-image: url("../images/plan-placeholder.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.plan-title {
  position: absolute;
  left: 40px;
  top: 40px;
  right: auto;
  bottom: auto;
  color: #05163d;
  font-size: 40px;
  line-height: 40px;
}

.plan-controls {
  position: absolute;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 20px;
}

.plan-button {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #ff521b;
  cursor: pointer;
}

.plan-button:hover {
  background-color: #05163d;
}

.plan-button.zoom-in {
  background-image: url("../images/plan-zoomin-icon.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.plan-button.zoomout {
  background-image: url("../images/plan-zoomout-icon.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.plan-button.zoomreset {
  background-image: url("../images/plan-zoomreset-icon.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.top-nav {
  position: fixed;
  left: 0px;
  top: 25px;
  right: 0px;
  z-index: 40;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.top-nav-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.top-nav-item {
  margin-right: 0px;
  margin-left: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #fff;
  color: #ff521b;
  cursor: pointer;
}

.top-nav-item:hover {
  background-color: #ff521b;
  color: #fff;
}

.top-nav-item.top-nav-active {
  background-color: #05163d;
  color: #fff;
}

.intro-portal-progress-bg {
  width: 0%;
  height: 6px;
  border-radius: 9px;
  background-color: #048837;
}

.loader {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 3000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e6e6e6;
}

.intro-people {
  position: absolute;
  left: auto;
  top: auto;
  right: -10%;
  bottom: 0%;
  width: 101%;
  height: 90%;
}

.intro-leaves {
  position: absolute;
  left: -6%;
  top: auto;
  right: auto;
  bottom: -2%;
  width: 120%;
  height: 51%;
}

.intro-skyline {
  position: absolute;
  left: -3%;
  top: auto;
  right: auto;
  bottom: -2%;
  width: 128%;
  height: 29%;
}

.intro-flower {
  position: absolute;
  left: -10%;
  top: 1%;
  right: auto;
  bottom: auto;
  width: 40%;
  height: 50%;
}

.intro-palm {
  position: absolute;
  left: 12%;
  top: -8%;
  right: auto;
  bottom: auto;
  width: 33%;
  height: 36%;
}

.intro-foreground {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
}

.intro-bike {
  position: absolute;
  left: 12%;
  top: auto;
  right: auto;
  bottom: -5px;
  width: 40%;
  height: 38%;
}

.intro-burger {
  position: absolute;
  left: auto;
  top: auto;
  right: 11%;
  bottom: -4%;
  width: 30%;
  height: 30%;
}

.intro-tree {
  position: fixed;
  left: 18%;
  top: auto;
  right: auto;
  bottom: -10px;
  width: 15%;
  height: 40%;
}

.intro-circle {
  position: absolute;
  left: auto;
  top: -1%;
  right: 36%;
  bottom: auto;
  width: 10%;
  height: 10%;
}

.intro-bee {
  position: fixed;
  left: 14%;
  top: 28%;
  right: auto;
  bottom: auto;
  width: 7%;
  height: 10%;
}

.intro-butterfly {
  position: fixed;
  left: auto;
  top: auto;
  right: 18%;
  bottom: 15%;
  width: 10%;
  height: 15%;
}

.intro-people-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-people.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-leaves-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-leaves.png");
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-skyline-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-skyline.svg");
  background-position: 0% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-flower-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-flower.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-palm-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-palm.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-bike-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-bike.png");
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-burger-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-burger.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-tree-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-tree.png");
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-circle-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-circle.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-bee-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-bee.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-butterfly-img {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-butterfly.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.amenities-hero-img {
  width: 55%;
  height: 20vw;
  max-height: 300px;
  background-image: url("../images/amenities-hero.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.amenities-hero-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.amenity-slide-copy {
  max-width: 430px;
  color: #05163d;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.amenity-img-left {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50vw;
  border-radius: 4px;
  background-color: #06262d;
  color: #fff;
  cursor: pointer;
}

.amenity-img-left:hover {
  color: #ff521b;
}

.amenity-2col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 35px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.amenity-desc-col {
  width: 70%;
  padding-right: 20px;
}

.amenity-sub-copy {
  padding-right: 20px;
  color: #048837;
  font-size: 12px;
}

.amenity-sub-copy.dark {
  color: #05163d;
}

.amenity-title {
  margin-bottom: 25px;
  color: #05163d;
  font-size: 30px;
  line-height: 36px;
}

.amenity-sub-col {
  line-height: 16px;
}

.amenity-view-map {
  color: #ff521b;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.amenity-view-map:hover {
  color: #05163d;
}

.amenity-minor-cont {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 39%;
  margin-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.amenity-img-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 35vw;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  background-color: #06262d;
  color: #fff;
  cursor: pointer;
}

.amenity-img-item:hover {
  color: #ff521b;
}

.amenity-major {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 58%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.amenity-major.amenity-major-full {
  width: 100%;
}

.amenity-walk-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.amenity-img-right {
  width: 95%;
  height: 400px;
  background-image: url("../images/intro-hero-img.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.amenity-right-align {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.contact-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 80px 40px 60px;
  color: #05163d;
}

.contact-left {
  width: 40%;
  padding-right: 90px;
}

.contact-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.contact-line {
  color: #05163d;
  font-size: 12px;
  line-height: 16px;
}

.axa-logo {
  width: 90px;
  height: 84px;
  margin-top: 10px;
  background-image: url("../images/axa_logo.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.download-area {
  margin-top: 70px;
  padding-top: 10px;
  border-top: 1px solid #05163d;
}

.downloads-list {
  margin-top: 25px;
}

.download-item {
  margin-bottom: 15px;
  padding-left: 30px;
  background-image: url("../images/dl_icon.svg");
  background-position: 0% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #048837;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.contact-names {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.contact-name-col {
  width: 50%;
}

.jll-logo {
  width: 113px;
  height: 55px;
  margin-bottom: 30px;
  background-image: url("../images/jll-logo.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.colliers-logo {
  width: 113px;
  height: 55px;
  margin-bottom: 30px;
  background-image: url("../images/colliers-logo.svg");
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.contact-name {
  margin-bottom: 15px;
  color: #05163d;
}

.name {
  color: #048837;
}

.misrep {
  max-width: 800px;
  font-size: 10px;
  line-height: 14px;
}

.email {
  color: #05163d;
  text-decoration: none;
}

.amenity-slide-top {
  position: relative;
  height: 300px;
  margin-bottom: 0px;
  padding-right: 40px;
}

.amenity-slide-top.full-width {
  height: auto;
  padding-right: 0px;
}

.buiilding-hero {
  width: 100%;
  height: 100%;
  background-image: url("../images/building-hero.jpg");
  background-position: 25% 70%;
  background-size: 300px;
  background-repeat: no-repeat;
}

.building-gfk1 {
  position: absolute;
  left: 4%;
  top: 7%;
  right: auto;
  bottom: auto;
  width: 16px;
  height: 16px;
  background-image: url("../images/building-gfk1.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.building-gfk2 {
  position: absolute;
  left: auto;
  top: 7%;
  right: 5%;
  bottom: auto;
  width: 20px;
  height: 27px;
  background-image: url("../images/building-gfk2.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.overlay-featuresust-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 135px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.overlay-featuresust-item {
  width: 48%;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #048837;
}

.sust-item {
  max-width: 500px;
  margin-bottom: 15px;
  padding-left: 19px;
  background-image: url("../images/spec-icon.svg");
  background-position: 0% 7px;
  background-size: auto;
  background-repeat: no-repeat;
  color: #05163d;
  font-size: 16px;
  line-height: 24px;
}

.spec-hero {
  width: 205px;
  height: 194px;
  margin-bottom: 20px;
  margin-left: -22px;
  background-image: url("../images/spec-hero.jpg");
  background-position: 0px 0px;
  background-size: contain;
}

.spec-img {
  margin-bottom: 15px;
}

.spec-img.breeam-spec {
  width: 121px;
  height: 93px;
  margin-left: -10px;
  background-image: url("../images/breeam-spec.jpg");
  background-position: 0px 0px;
  background-size: contain;
}

.spec-img.occupancy-spec {
  width: 140px;
  height: 89px;
  margin-bottom: 17px;
  background-image: url("../images/occupancy-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.vrf-spec {
  width: 109px;
  height: 84px;
  margin-bottom: 18px;
  background-image: url("../images/vrf-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.air-spec {
  width: 112px;
  height: 81px;
  margin-bottom: 18px;
  background-image: url("../images/air-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.led-spec {
  width: 125px;
  height: 89px;
  background-image: url("../images/led-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.roof-spec {
  width: 117px;
  height: 91px;
  background-image: url("../images/roof-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.ceiling-spec {
  width: 115px;
  height: 86px;
  background-image: url("../images/ceiling-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.cycle-spec {
  width: 109px;
  height: 82px;
  background-image: url("../images/cycle-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.park-spec {
  width: 104px;
  height: 85px;
  background-image: url("../images/park-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.showers-spec {
  width: 96px;
  height: 89px;
  background-image: url("../images/showers-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.wired-spec {
  width: 110px;
  height: 92px;
  background-image: url("../images/wired-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-img.epc-spec {
  width: 108px;
  height: 91px;
  background-image: url("../images/epc-spec.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.spec-label {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.spec-cols {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.spec-title {
  color: #ff521b;
}

.spec-sub {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}

.burger-content {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 300px;
  padding: 40px 60px 80px;
}

.burger-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #ff521b;
}

.burger-col-list {
  position: relative;
  z-index: 2500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
}

.burger-col-item {
  width: 20%;
}

.burger-list {
  margin-bottom: 0px;
  padding-left: 0px;
}

.burger-item {
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.6px;
  cursor: pointer;
}

.burger-item:hover {
  color: #05163d;
}

.burger-item-divider {
  width: 60px;
  height: 1px;
  margin-top: 22px;
  margin-bottom: 23px;
  background-color: #fff;
}

.wellness-gallery-text-list {
  padding-left: 0px;
}

.amenity-img-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 35px;
  padding-right: 40px;
}

.amenity-img-hero:hover {
  color: #ff521b;
}

.amenity-img-hero.amenity-img-hero-right {
  padding-right: 0px;
  padding-left: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.travel-times-title {
  margin-bottom: 15px;
  text-align: center;
}

.travel-time-list {
  margin-bottom: 40px;
  color: #05163d;
}

.travel-time-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  padding: 8px 15px 8px 26px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 2px;
  background-color: #f6f6f6;
  cursor: pointer;
}

.travel-time-item.travel-item-inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.travel-item-left {
  width: 100%;
  padding-right: 10px;
}

.travel-item-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.travel-item-title {
  position: relative;
  font-size: 13px;
  font-weight: 700;
}

.travel-icon-tube {
  position: relative;
  left: 0px;
  top: 0px;
  width: 16px;
  height: 12px;
  background-image: url("../images/t-icon-tube.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.travel-item-label {
  color: #048837;
  font-size: 12px;
  line-height: 17px;
  font-style: italic;
}

.travel-time {
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 13px;
  text-align: right;
}

.travel-time.bike-time {
  padding-left: 24px;
  background-image: url("../images/t-icon-bike.svg");
  background-position: 0% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.travel-time.walk-time {
  padding-left: 15px;
  background-image: url("../images/t-icon-walk.svg");
  background-position: 0% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.travel-time.car-time {
  padding-left: 32px;
  background-image: url("../images/t-icon-car.svg");
  background-position: 0% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  font-weight: 500;
}

.travel-icon-overground {
  position: relative;
  left: 0px;
  top: 0px;
  width: 16px;
  height: 12px;
  background-image: url("../images/t-icon-overground.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.travel-icon-rail {
  background-image: url("../images/t-icon-rail.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.italic-text {
  line-height: 16px;
}

.travel-icon-plane {
  background-image: url("../images/t-icon-airport.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.travel-time-expand {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 19px;
  height: 19px;
  border-radius: 3px;
  background-color: #ff521b;
  background-image: url("../images/plan-zoomin-icon.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.travel-time-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.travel-item-expandable-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.travel-cat-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-top: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.travel-cat-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.travel-cat-dot {
  width: 7px;
  height: 7px;
  margin-right: 4px;
  margin-bottom: 1px;
  border-radius: 20px;
  background-color: #000;
}

.travel-cat-label {
  font-size: 12px;
  line-height: 14px;
}

.travel-subtime-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.travel-subtime-label {
  font-size: 13px;
}

.travel-subtime-divider {
  height: 1px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #d8d8d8;
}

.travel-time-contract {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 19px;
  height: 19px;
  border-radius: 3px;
  background-color: #05163d;
  background-image: url("../images/plan-zoomout-icon.svg");
  background-position: 50% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.bike-icon {
  background-image: url("../images/t-icon-bike.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.travel-icon-crossrail {
  background-image: url("../images/t-icon-crossrail.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.virtual {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.virtual-container {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.slide-render-reserve {
  display: none;
}

.compass {
  position: fixed;
  left: auto;
  top: 80px;
  right: 40px;
  bottom: auto;
  width: 30px;
  height: 30px;
  background-image: url("../images/compass.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.amenity-info-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 150;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 20px 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.amenity-info-fade {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 100;
  width: 100%;
  height: 155px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#020a1b),
    to(rgba(2, 10, 27, 0))
  );
  background-image: linear-gradient(0deg, #020a1b, rgba(2, 10, 27, 0));
}

.amenity-img {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-image: url("../images/intro-hero-img.jpg");
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
}

.amenity-walk-time {
  padding: 4px 8px 4px 20px;
  border-radius: 4px;
  background-color: #fff;
  background-image: url("../images/t-icon-walk.svg");
  background-position: 13% 44%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #05163d;
  font-size: 12px;
}

.amenity-title-block {
  width: 100%;
}

.amenity-header {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
}

.amenity-learn-button {
  width: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 20px;
  background-image: url("../images/button-arrow.svg");
  background-position: 0% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  font-size: 11px;
  cursor: pointer;
}

.amenity-learn-button:hover {
  color: #ff521b;
}

.amenity-info-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 40px 40px 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.amenity-info-gallery {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.amenity-info-gallery-controls {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.amenity-gallery-button {
  width: 45px;
  height: 45px;
  background-color: #ff521b;
  cursor: pointer;
}

.amenity-gallery-button:hover {
  background-color: #05163d;
}

.amenity-gallery-button.prev-button {
  background-image: url("../images/gallery-prev.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.amenity-gallery-button.next-button {
  background-image: url("../images/gallery-next.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.amenity-gallery-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.amenity-walk-times {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 13px;
  background-image: url("../images/t-icon-walk.svg");
  background-position: 0% 52%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #fff;
}

.amenity-info-title {
  margin-top: 10px;
  margin-bottom: 15px;
  color: #ff521b;
  font-size: 30px;
  font-weight: 500;
}

.amenity-info-copy {
  max-width: 560px;
  color: #fff;
  line-height: 22px;
  font-weight: 500;
}

.amenity-info-gfx {
  position: absolute;
  left: auto;
  top: -45%;
  right: -2%;
  bottom: auto;
  width: 130px;
  height: 130px;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.super {
  position: relative;
  top: -5px;
  font-size: 10px;
}

.indent {
  position: relative;
  left: 20px;
  padding-left: 0px;
}

.loading-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 157px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.loading-spinner {
  width: 28px;
  height: 28px;
  margin-bottom: 10px;
  background-image: url("../images/fold-spinner.gif");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 991px) {
  .nav {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    padding-right: 20px;
    padding-left: 20px;
  }

  .nav-content {
    padding-left: 100px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-radius: 0px 0px 8px 8px;
    background-image: url("../images/fold-logo-horiz.svg");
    background-position: 2% 50%;
    background-size: auto;
    background-repeat: no-repeat;
  }

  .nav-section-title {
    display: none;
  }

  .instructions {
    padding-left: 0px;
  }

  .instruction {
    font-size: 8px;
  }

  .nav-burger {
    width: 60px;
  }

  .slide-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 50px 20px 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .slide-content.connections-content {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .slide-content.amenity-info-content {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .slide-content.amenity-content {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .slide-panel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-image: none;
  }

  .slide-panel.floors-panel {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .slide-panel.amenity-panel {
    padding-bottom: 40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .slide-panel.amenity-info-panel {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .overview-slide-top {
    width: 50%;
    margin-bottom: 29px;
  }

  .overview-slide-top.full-width {
    width: 100%;
  }

  .overview-slide-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .overview-slide-content.connections-content {
    width: 100%;
  }

  .slide-nav {
    margin-top: 15px;
  }

  .slide-sub-panel {
    width: 100%;
  }

  .slide-sub-panel.floor-sub {
    margin-top: 10px;
  }

  .connections-hero {
    background-position: 41% 50%;
  }

  .floor-title {
    margin-top: 15px;
  }

  .floor-selector-list {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .floor-selector-item {
    padding: 6px 10px;
  }

  .intro-portal {
    width: 90%;
  }

  .intro-loader-container {
    width: 90%;
  }

  .loader-logo {
    margin-top: 35px;
    margin-bottom: 4px;
  }

  .overlay {
    padding-right: 20px;
    padding-left: 20px;
  }

  .overlay-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .overlay-content.wellness-content {
    min-height: 50vh;
  }

  .overlay-col-left {
    width: 100%;
    padding-left: 30px;
  }

  .overlay-col-right {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .overlay-featurespec-item {
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .plan-title {
    left: 25px;
  }

  .top-nav {
    display: none;
  }

  .loader {
    padding-top: 125px;
  }

  .amenities-hero-img {
    height: 27vw;
  }

  .amenity-2col {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .contact-right {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .contact-names {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .contact-name-col {
    width: 100%;
    margin-bottom: 50px;
  }

  .amenity-slide-top.full-width {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .overlay-featuresust-list {
    padding-top: 0px;
  }

  .overlay-featuresust-item {
    width: 50%;
    border-bottom-style: none;
  }

  .burger-content {
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .burger-col-item {
    width: 100%;
    font-size: 12px;
  }

  .burger-item {
    font-size: 18px;
    line-height: 22px;
  }

  .amenity-img-hero {
    padding-right: 20px;
  }

  .amenity-img-hero.amenity-img-hero-right {
    padding-left: 20px;
  }

  .travel-time-list {
    width: 80%;
  }

  .slide-render-reserve {
    display: none;
  }

  .slide-render-reserve.connections-reserve {
    display: block;
    height: 50vh;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .slide-render-reserve.amenity-reserve {
    display: block;
    width: 100%;
    height: 50vh;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .compass {
    top: 50px;
    right: 32px;
  }

  .amenity-info-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .amenity-info-content.connections-content {
    width: 100%;
  }

  .amenity-info-gallery {
    height: 50vw;
  }

  .amenity-info-gfx {
    right: 3%;
  }

  .loading-div {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) {
  .instructions {
    position: fixed;
    top: 45px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    font-size: 9px;
    line-height: 10px;
  }

  .instruction {
    font-size: 9px;
  }

  .slide-content {
    padding-top: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .slide-content.floors-content {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .slide-panel {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .slide-panel.floors-panel {
    width: 100%;
    margin-right: 10px;
    padding-bottom: 10px;
  }

  .slide-panel.contact-panel {
    height: auto;
  }

  .overview-slide-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .overview-img-container {
    width: 100%;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .overview-slide-content {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .overview-slide-content.connections-content {
    padding-right: 20px;
    padding-left: 20px;
  }

  .slide-nav {
    margin-top: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .slide-sub-panel.floor-sub {
    height: 100%;
    margin-top: 0px;
  }

  .slide-sub-button-label {
    padding-left: 10px;
  }

  .amenities-hero {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .intro-portal {
    width: 95%;
  }

  .intro-loader-container {
    width: 95%;
  }

  .intro-fold-logo {
    width: 360px;
  }

  .overlay {
    padding-top: 40px;
  }

  .overlay-featurespec-item {
    width: 100%;
  }

  .wellness-gallery-image {
    width: 90%;
  }

  .plan-title {
    left: 20px;
  }

  .intro-people-img {
    background-position: 50% 100%;
  }

  .amenities-hero-img {
    width: 100%;
    margin-bottom: 26px;
  }

  .amenities-hero-copy {
    width: 100%;
  }

  .amenity-img-left {
    height: 58vw;
  }

  .amenity-2col {
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .amenity-minor-cont {
    width: 100%;
  }

  .amenity-img-item {
    height: 50vw;
    margin-bottom: 20px;
  }

  .amenity-major {
    width: 100%;
  }

  .contact-content {
    padding-right: 25px;
    padding-left: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .contact-left {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .contact-right {
    width: 100%;
    margin-bottom: 48px;
  }

  .overlay-featuresust-item {
    width: 100%;
  }

  .burger-content {
    height: auto;
  }

  .burger-col-list {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .burger-col-item {
    width: 50%;
    margin-bottom: 36px;
  }

  .amenity-img-hero {
    margin-bottom: 20px;
    padding-right: 0px;
  }

  .amenity-img-hero.amenity-img-hero-right {
    padding-left: 0px;
  }

  .travel-time-list {
    width: 100%;
  }

  .slide-render-reserve {
    display: block;
    height: 50vh;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .slide-floors-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .amenity-info-content {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .amenity-info-content.connections-content {
    padding-right: 20px;
    padding-left: 20px;
  }

  .amenity-info-gfx {
    top: -22%;
    width: 90px;
    height: 100px;
  }
}

@media screen and (max-width: 479px) {
  .nav {
    padding-right: 5px;
    padding-left: 5px;
  }

  .instructions {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .slide-content {
    padding-right: 5px;
    padding-left: 5px;
  }

  .slide-panel.floors-panel {
    padding-right: 10px;
    padding-left: 15px;
  }

  .slide-panel.amenity-panel {
    padding-bottom: 0px;
  }

  .overview-slide-top {
    padding-right: 10px;
    padding-left: 10px;
  }

  .overview-slide-content {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .slide-nav-item {
    font-size: 14px;
  }

  .slide-sub-panel.floor-sub {
    width: 130px;
  }

  .slide-sub-buttons {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .slide-sub-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .slide-sub-button-label {
    font-size: 16px;
  }

  .amenities-hero {
    padding: 40px 20px;
  }

  .floor-selector-label {
    min-width: 37px;
  }

  .intro-fold-logo {
    width: 290px;
    height: 111px;
  }

  .intro-start-button {
    margin-top: 40px;
  }

  .intro-sub-container {
    padding-right: 5px;
    padding-left: 5px;
  }

  .intro-sub-content {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 12px;
  }

  .overlay {
    padding: 0px;
  }

  .overlay-content {
    min-height: 100vh;
    border-radius: 0px;
  }

  .overlay-col-left {
    padding-left: 20px;
  }

  .overlay-col-right {
    padding-right: 20px;
    padding-left: 20px;
  }

  .overlay-close {
    width: 40px;
    height: 40px;
  }

  .wellness-gallery-image {
    width: 100%;
    height: 300px;
  }

  .wellness-gallery-text {
    font-size: 30px;
    line-height: 34px;
  }

  .plan-title {
    font-size: 30px;
    line-height: 32px;
  }

  .plan-controls {
    right: 10px;
  }

  .loader {
    padding-top: 135px;
  }

  .intro-people {
    width: 140%;
  }

  .amenities-hero-img {
    height: 43vw;
  }

  .amenity-img-left {
    height: 65vw;
  }

  .amenity-2col {
    padding-right: 0px;
    padding-left: 0px;
  }

  .amenity-img-item {
    height: 65vw;
    margin-bottom: 0px;
  }

  .burger-content {
    padding-top: 77px;
  }

  .burger-col-item {
    width: 100%;
  }

  .amenity-img-hero {
    margin-bottom: 0px;
  }

  .compass {
    right: 20px;
  }

  .amenity-info-container.amenity-info-wide {
    padding: 10px 15px 20px;
  }

  .amenity-header {
    font-size: 24px;
  }

  .amenity-info-content {
    padding: 40px 20px;
  }

  .amenity-info-gallery {
    height: 80vw;
  }

  .amenity-info-title {
    font-size: 22px;
  }

  .amenity-info-gfx {
    top: -12%;
  }
}

@font-face {
  font-family: "Circular";
  src: url("../fonts/lineto-circular-medium.woff2") format("woff2"),
    url("../fonts/lineto-circular-medium.eot") format("embedded-opentype"),
    url("../fonts/lineto-circular-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Circular";
  src: url("../fonts/lineto-circular-bold.woff2") format("woff2"),
    url("../fonts/lineto-circular-bold.eot") format("embedded-opentype"),
    url("../fonts/lineto-circular-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* The Modal (background) */
.video-modal {
  display: none; 
  position: fixed; 
  z-index: 9999; 
  padding: 0;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.7);
  pointer-events: initial;
}

/* Modal Content */
.modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  width: 80%;
  max-width: 1400px;
}

/* Close Button */
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 50px;
  font-weight: bold;
  cursor: pointer;
  z-index: 9999;
}

.close:hover,
.close:focus {
  color: #ff521b;
  text-decoration: none;
  cursor: pointer;
}

.vid-trigger {
  background-color: #ff521b;
  color: #fff;
}