.body {
  overflow-x: hidden;
  overflow-y: scroll;
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.no-hscroll {
  overflow-x: hidden;
}

.render {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: all;
}

.app {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
}

.ui {
  display: relative;
  width: 100%;
  height: 100%;
}

.no-event {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
}

.hidden {
  display: none !important;
}

.debug {
  position: relative;
  z-index: 1100;
}

.fade,
.auto-fade,
.fade-out {
  opacity: 0 !important;
}

.anim-all-200 {
  transition: all ease-in-out 200ms;
}

.anim-all-400 {
  transition: all ease-in-out 400ms;
}

.anim-slide-up {
  transform: translateY(20px);
}

.debug-stats-panel {
  position: fixed;
  left: 45px;
  top: 45px;
}

.interface {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.tracker-item {
  margin-bottom: 5px;
  padding: 8px 5px 5px 10px;
  border-left-style: solid;
  border-left-width: 3px;
  background-color: #e9e9e9;
}

.tracker-item.variable-item {
  border-left-color: #9510e0;
}

.tracker-item.entity-item {
  border-left-color: #00ff9a;
  cursor: pointer;
}

.tracker-item.entity-item:hover {
  background-color: silver;
}

.tracker-item.entity-item.active {
  background-color: #1f191b;
  color: #fff;
}

.state-label {
  height: 45px;
  min-width: 200px;
  padding-top: 12px;
  padding-left: 16px;
  border-left: 2px solid #00ff9a;
  border-radius: 3px;
  background-color: #1f191b;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.state-label {
  cursor: pointer;
}

.state-selector-list {
  display: none;
}

.state-label-wrapper:hover .state-selector-list {
  display: block;
}

.tracker-wrap.config-items-wrap {
  padding-top: 15px;
}

.tracker-wrap.variables-wrap {
  margin-top: 15px;
}

.tracker-title {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
}

.tracker-title.selected-var-title {
  margin-bottom: 10px;
}

.entity-value {
  font-size: 10px;
  font-weight: 600;
}

.state-label-wrapper {
  position: relative;
}

.state-nav-wrapper {
  position: fixed;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 25px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.tracker-list {
  margin-top: 5px;
}

.state-list-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 16px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.state-list-item:hover {
  color: #00ff9a;
}

.debug-wrap {
  position: absolute;
  left: 20px;
  top: 90px;
  z-index: 1000;
  width: 200px;
  padding: 10px;
  border-top: 3px solid #000;
  background-color: #fff;
}

.debug-wrap.debug-wrap-config {
  position: fixed;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 100px;
}

.debug-wrap.debug-floor-config {
  position: fixed;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 300px;
  display: none;
}

.debug-wrap.selected-vars-wrap {
  position: fixed;
  left: 235px;
  top: 90px;
  right: auto;
  padding-bottom: 0px;
  border-radius: 3px;
  background-color: #1f191b;
  color: #fff;
}

.state-button {
  width: 45px;
  height: 45px;
  margin-left: 5px;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
}

.state-button:hover {
  background-color: #616161;
}

.state-button.next-button {
  background-image: url("../images/next-arrow.svg");
  background-position: 55% 50%;
  background-repeat: no-repeat;
}

.state-button.prev-button {
  background-image: url("../images/prev-arrow.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.state-selector-list {
  padding: 10px 0px;
  border-radius: 3px;
  background-color: #1f191b;
}

.reset-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 3px;
  background-color: #fff;
  background-image: url("../images/reset-icon.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #616161;
}

.show-floor-button {
  height: 45px;
  margin-right: 15px;
  padding-top: 12px;
  padding-right: 12px;
  padding-left: 40px;
  border-radius: 3px;
  background-color: #fff;
  background-image: url("../images/radial2.svg");
  background-position: 13px 50%;
  background-repeat: no-repeat;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.show-floor-button:hover {
  background-color: #1f191b;
  color: #fff;
}

.show-floor-button.active {
  background-image: url("../images/radial2-checked.svg");
}

.config-list-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  background-image: url("../images/radial.svg");
  background-position: 0px 50%;
  background-repeat: no-repeat;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.config-list-item:hover {
  color: #f0524a;
}

.config-list-item.active {
  background-image: url("../images/radial-checked.svg");
}

.entity-title {
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}

.debug.hidden {
  display: none;
}

.content-fade {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 200px;
  opacity: 0.5;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#182d42),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(90deg, #182d42, hsla(0, 0%, 100%, 0));
}

.content-right-fade {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 100px;
  opacity: 0.25;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#182d42),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(270deg, #182d42, hsla(0, 0%, 100%, 0));
}

.content-top-fade {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: 140px;
  opacity: 0.25;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#182d42),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #182d42, hsla(0, 0%, 100%, 0));
}

.content-bottom-fade {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: 140px;
  opacity: 0.25;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#182d42),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(0deg, #182d42, hsla(0, 0%, 100%, 0));
}

.feature-item {
  background-image: none;
  background-repeat: no-repeat;
  background-position: 0% 50%;
}

.truck-popup-img {
  background-image: url("../images/truck-popup-img.jpg");
}
.access-icon {
  background-image: url("../images/access-icon.svg");
}
.charging-icon {
  background-image: url("../images/charging-icon.svg");
}
.depth-icon {
  background-image: url("../images/depth-icon.svg");
}
.loader,
.loader-text {
  transition: opacity 400ms ease-in-out;
}

.content-fade-container {
  z-index: -1;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.overlay-img {
  transition: opacity 200ms ease-in-out;
}

.intro-portal-progress-bg {
  transition: all 400ms ease-in-out;
}

.loader-bar-outro {
  height: 100%;
  /* width: auto; */
  /* width: 100%; */
  border-radius: 0px;
}

.intro-loader-container {
  transition: all 200ms ease-in-out;
}

.intro-icon {
  opacity: 1;
  transition: all 600ms ease-in-out;
}

.loader,
.start-init {
  clip-path: polygon(
    100% 50%,
    100% 100%,
    0px 100%,
    0px 0px,
    100% 0px,
    100% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%
  );
  transition: all 2000ms ease-in;
}

.start-init {
  transition: all 1200ms ease-in;
}

.loader-outro,
.start-outro {
  clip-path: polygon(
    100% 50%,
    100% 100%,
    0px 100%,
    0px 0px,
    100% 0px,
    100% 50%,
    116.667% 50%,
    115.302% 29.8701%,
    111.264% 10.5644%,
    104.718% -7.12682%,
    95.9311% -22.4793%,
    85.2643% -34.8644%,
    73.1537% -43.7752%,
    60.0952% -48.8468%,
    46.6234% -49.8717%,
    33.2898% -46.8077%,
    20.6404% -39.7805%,
    9.19294% -29.0776%,
    -0.583875% -15.1372%,
    -8.28978% 1.4698%,
    -13.6093% 20.0637%,
    -16.3246% 39.8832%,
    -16.3246% 60.1168%,
    -13.6093% 79.9363%,
    -8.28978% 98.5302%,
    -0.583875% 115.137%,
    9.19294% 129.078%,
    20.6404% 139.78%,
    33.2898% 146.808%,
    46.6234% 149.872%,
    60.0952% 148.847%,
    73.1537% 143.775%,
    85.2643% 134.864%,
    95.9311% 122.479%,
    104.718% 107.127%,
    111.264% 89.4356%,
    115.302% 70.1299%,
    116.667% 50%
  );
}

.intro-portal,
.intro-outside-portal {
  transition: all 2000ms ease-in;
}

.intro-portal-outro {
  transform: scale(4);
}

.intro-outside-portal {
  position: fixed;
  width: 100%;
  height: 100%;
}

.intro-slide-item {
  transition: opacity 400ms ease-in-out;
}

.intro-hero-img {
  transition: all 1500ms ease-out;
  transform: scale(1);
}

.intro-hero-img-init {
  transform: scale(0.8);
}
.intro-hero-img-outro {
  transform: scale(1.2);
}

.fold-outline {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #05163d;
}

.burger-bg {
  clip-path: circle(0% at 95% 100%);
  transition: all 400ms ease-in-out;
}

.burger-bg.burger-bg-open {
  clip-path: circle(150% at 95% 100%);
}

.burger-col-item {
  opacity: 0;
  transform: translateY(30px);
  transition: all 400ms ease-out;
}

.burger-col-item.burger-bg-open {
  opacity: 1;
  transform: translateY(0px);
}

/* .slide-content,
.overlay {
  overflow-x: hidden;
  overflow-y: auto;
} */

.gallery-image {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.wellness-gallery-image {
  transform: translateX(0px);
  opacity: 1;
  transition: all 400ms ease-in-out;
}

.wellness-gallery-image.outro {
  transform: translateX(-50px);
  opacity: 0;
}

.wellness-gallery-image.reset {
  transform: translateX(50px);
  transition: all 0ms linear;
  opacity: 0;
}

.wellness-gallery-image.intro {
  transform: translateX(50px);
  /* transition: all 400ms ease-in-out; */
  opacity: 0;
}

.wellness-gallery-text-list {
  z-index: 1000;
}

.dot-circle-line {
  background-color: #ffd000;
}

.dot-district-line {
  background-color: #048837;
}

.dot-piccadilly-line {
  background-color: #1b3a93;
}

.dot-overground-line {
  background-color: #f67021;
}

.dot-rail-line {
  background-color: #ff0000;
}

.dot-hammersmith-line {
  background-color: #f77fa5;
}

.travel-time-item:hover .travel-time-expand {
  background-color: #05163d;
}

.overlay {
  overflow-x: hidden;
  overflow-y: auto;
}

.overlay-close {
  z-index: 5000;
}

.amenity-img-item,
.amenity-img-left {
  cursor: pointer;
}

.amenity-img-item:hover,
.amenity-img-left:hover {
  color: #ff521b;
}
.floor-selector-item:last-child {
  border-bottom: none;
}
.amenity-img,
.amenity-gallery-image {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}

.email:hover {
  color: #048837;
}

.dl:hover {
  color: #05163d;
}

.loading-div,
.loading-text {
  opacity: 1;
  transition: opacity 100ms ease-in-out;
}

.amenity-major-full .amenity-img {
  background-position: 50% 50%;
}

.amenity-info-copy {
  white-space: pre-line;
}

.more-details-btn {
  display: inline-block;
  border-radius: 6px;
  background-color: #ff521b;
  color: white;
  cursor: pointer;
  padding: 15px 20px;
  margin-bottom: 30px;
}

.more-details-btn:hover {
  background-color: #05163d;
}

.overview-img-container {
  background-image: url("../images/overview-1.jpg");
}
/* .intro-hero-img {
  background-image: url("../images/overview-3.jpg");
} */
